var app = {
    init: function () {
        // show wechat reveal on click
        $(".wechat-link").on("click", function(event) {
             event.preventDefault;

             $(".wechat-reveal").show();
         });

        // hide wechat reveal on click
        $(".wechat-reveal").on("click", function(event) {
            if (event.target.nodeName.toLowerCase() != 'img' && $(event.target).hasClass("wechat-text") == false) {
                $(event.currentTarget).hide();
            }
        });
    }
};

// initialize app
$(document).ready(function () {
    app.init();
});
